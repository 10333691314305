import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    minHeight: 160,
  },
}));

type SheetParams = {
  className?: string;
};

const Sheet: React.FC<SheetParams> = ({ className, children }) => {
  const classes = useStyles();
  return (
    <Paper className={clsx(classes.paper, className && className)}>
      {children}
    </Paper>
  );
};

export default Sheet;
