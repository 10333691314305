import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export type RefundProps = {
  leadId: number;
  staffEvalDate: string;
  nameKanji: string;
  nameKana: string;
  claimDate: string;
  reason: string;
  reasonDetails: string;
  closedAt: string;
};

type RefundTableProps = {
  refunds: RefundProps[];
};

const useStyles = makeStyles(() => ({
  tableText: {
    whiteSpace: 'nowrap',
  },
  tableSubTitle: {
    paddingLeft: 7,
    marginTop: 24,
  },
  reasonDetails: {
    maxWidth: 150,
    minWidth: 150,
    whiteSpace: 'normal',
  },
}));

const RefundTable: React.FC<RefundTableProps> = ({ refunds }) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.tableSubTitle}>Refunds</Typography>
      <Table size="small" className={classes.tableText}>
        <TableHead>
          <TableRow>
            <TableCell>Lead ID</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Claim Date</TableCell>
            <TableCell>Claim Reason</TableCell>
            <TableCell>Claim Details</TableCell>
            <TableCell>Closed AT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {refunds.map((refund) => (
            <TableRow key={refund.leadId}>
              <TableCell>{refund.leadId}</TableCell>
              <TableCell>{refund.staffEvalDate}</TableCell>
              <TableCell>
                {refund.nameKanji}
                <br />
                {refund.nameKana}
              </TableCell>
              <TableCell>{refund.claimDate}</TableCell>
              <TableCell>{refund.reason}</TableCell>
              <TableCell>
                <div className={classes.reasonDetails}>
                  {refund.reasonDetails}
                </div>
              </TableCell>
              <TableCell>{refund.closedAt}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default RefundTable;
