import React, { useState, useEffect, useRef } from 'react';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import useAxios from '../../lib/hooks/useAxios';
import BillingCycle from './BillingCycle';
import PageTemplate from '../PageTemplate';
import { Sheet, Error, PendingBar } from '../../components';

const SimpleInvoice: React.FC = () => {
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const unmounted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [billingCycles, setBillingCycles] = useState<{bc_id: number, run_date: string}[]>([]);
  const [installerInvoiceFileNames, setInstallerInvoiceFileNames] = useState<{ [key: string]: string[] }>();
  const [installerHasNoInvoices, setInstallerHasNoInvoices] = useState(false);
  const { getInstallerBillingCycles, getInstallerInvoiceFileNames } = useAxios();

  useEffect(() => {
    const fetchBillingCycles = async () => {
      setIsLoading(true);
      const response = await getInstallerBillingCycles();
      if (!unmounted.current) {
        if (response.status === 200) {
          if (response.data.length === 0) {
            setInstallerHasNoInvoices(true);
          } else {
            setBillingCycles(response.data);
            const { data: fileNames } = await getInstallerInvoiceFileNames();
            setInstallerInvoiceFileNames(fileNames);
          }
        } else {
          setShouldDisplayError(true);
        }
        setIsLoading(false);
      }
    };
    fetchBillingCycles();
    // Prevent react from trying to update state if component get unmounted
    return () => { unmounted.current = true; };
  }, []);

  return (
    <PageTemplate pageTitle="案件明細ダウンロード">
      {shouldDisplayError && <Error shouldDisplay={shouldDisplayError} />}
      {!shouldDisplayError && (
        <Sheet>
          <List>
            {installerHasNoInvoices && (
              <Typography>Installer has no invoices</Typography>
            )}
            {isLoading && <PendingBar />}
            {installerInvoiceFileNames && billingCycles.map((bc, index) => (
              <BillingCycle key={bc.bc_id} billingCycleId={bc.bc_id} runDate={bc.run_date} fileNames={installerInvoiceFileNames[bc.bc_id]} isDefaultOpen={index === 0} />
            ))}
          </List>
        </Sheet>
      )}
    </PageTemplate>
  );
};

export default SimpleInvoice;
