import XLSX from 'xlsx';

const downloadInvoice = (fileName: string, invoiceData: any) => {
  if (fileName.includes('xlsx')) {
    const ws = XLSX.utils.json_to_sheet(invoiceData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws);
    XLSX.writeFile(wb, fileName);
  } else if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(
      new Blob([invoiceData]),
      decodeURIComponent(fileName),
    ); // for ie10
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(new Blob([invoiceData]));
    a.href = url;
    a.download = decodeURIComponent(fileName);
    a.target = '_blank';
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  }
};

export default downloadInvoice;
