import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LeadTable, { LeadProps } from './LeadTable';
import RefundTable, { RefundProps } from './RefundTable';

const useStyles = makeStyles(() => ({
  tableText: {
    whiteSpace: 'nowrap',
  },
  downloadButton: {
    margin: '14px 0',
    backgroundColor: '#5d2f91',
    color: '#fff',
    '&:hover': {
      color: '#5d2f91',
    },
  },
  tableTitle: {
    border: 'none',
    display: 'flex',
    width: '100%',
    backroundColor: '#fff',
    outline: 'none',
    cursor: 'pointer',
    textAlign: 'left',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      color: '#5d2f91',
    },
  },
  tableTitleIcon: {
    color: '#5d2f91',
  },
  tableTitleText: {
    marginTop: 7,
  },
  tableSubTitle: {
    paddingLeft: 7,
  },
  collapseBody: {
    overflowX: 'auto',
  },
}));

const billingCycleIdToDate = (billingCycleId: number): string => {
  const bcIdString = String(billingCycleId);
  return `${bcIdString.slice(0, 4)}/${bcIdString.slice(
    4,
    6,
  )}/${bcIdString.slice(6)}`;
};

type TableProps = {
  isDefaultOpen?: boolean;
  billingCycleId: number;
  leads: LeadProps[];
  refunds: RefundProps[];
};

const InvoiceTable: React.FC<TableProps> = ({ billingCycleId, isDefaultOpen, leads, refunds }) => {
  const [isOpen, setisOpen] = useState(false);
  useEffect(() => {
    if (isDefaultOpen) {
      setisOpen(true);
    }
    // Only wnat this to run on init
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();
  return (
    <>
      <Button
        className={clsx(classes.tableTitle, 'MuiListItem-button')}
        onClick={() => setisOpen(!isOpen)}
      >
        <Typography
          component="h2"
          variant="h6"
          gutterBottom
          className={classes.tableTitleText}
        >
          {billingCycleIdToDate(billingCycleId)} までの明細
        </Typography>
        {!isOpen && (
          <KeyboardArrowDownIcon
            fontSize="large"
            className={classes.tableTitleIcon}
          />
        )}
        {isOpen && (
          <KeyboardArrowUpIcon
            fontSize="large"
            className={classes.tableTitleIcon}
          />
        )}
      </Button>
      <Collapse in={isOpen} className={clsx(isOpen && classes.collapseBody)}>
        <LeadTable leads={leads} />
        <RefundTable refunds={refunds} />
        <Button variant="outlined" className={classes.downloadButton}>
          ダウンロード
        </Button>
      </Collapse>
      <Divider />
    </>
  );
};

export default InvoiceTable;
