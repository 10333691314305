import LinearProgress from '@material-ui/core/LinearProgress';
import { lighten, withStyles } from '@material-ui/core/styles';

const PendingBar = withStyles({
  root: {
    backgroundColor: lighten('#5d2f91', 0.5),
  },
  bar: {
    backgroundColor: '#5d2f91',
  },
})(LinearProgress);

export default PendingBar;
