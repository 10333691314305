import React from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useAuth0 } from './react-auth0-wrapper';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import useAuthenticated from './lib/hooks/useAuthenticated';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'ヒラギノ角ゴ Pro',
      'Hiragino Kaku Gothic Pro',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

const App: React.FC = () => {
  const classes = useStyles();
  useAuthenticated();

  const { isAuthenticated, user } = useAuth0();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        {isAuthenticated && user && <AuthenticatedApp />}
        {!isAuthenticated && <UnauthenticatedApp />}
      </div>
    </ThemeProvider>
  );
};

export default App;
