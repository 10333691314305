import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Header, Loading, AppWrapper } from './components';
import { Login } from './pages';

const UnauthenticatedApp: React.FC = () => (
  <>
    <Header handleDrawerOpen={() => { }} drawerOpen={false} />
    <Loading />
    <AppWrapper>
      <Switch>
        <Route path="/" component={Login} />
      </Switch>
    </AppWrapper>
  </>
);

export default UnauthenticatedApp;
