import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  copyright: {
    marginTop: 'auto',
  },
}));

const Copyright: React.FC = () => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.copyright}
      variant="body2"
      color="textSecondary"
      align="center"
    >
      {`Copyright © ${new Date().getFullYear()} TEPCO Ventures, Inc. All rights reserved.`}
    </Typography>
  );
};

export default Copyright;
