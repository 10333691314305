import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LogoWithText from '../LogoWithText/LogoWithText';
import { useAuth0 } from '../../react-auth0-wrapper';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    height: '100%',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: 90,
    backgroundColor: '#fff',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    color: '#5d2f91',
    marginRight: 36,
    '@media (max-width: 767px)': {
      marginRight: 8,
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  notAuthenticated: {
    marginLeft: 84,
    '@media (max-width: 767px)': {
      marginLeft: 44,
    },
  },
}));

type HeaderProps = {
  drawerOpen: boolean;
  handleDrawerOpen: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
};

const Header: React.FC<HeaderProps> = ({ drawerOpen, handleDrawerOpen }) => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();
  return (
    <AppBar
      elevation={1}
      position="absolute"
      className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        {isAuthenticated && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              drawerOpen && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
        )}
        <div className={clsx(!isAuthenticated && classes.notAuthenticated)}>
          <LogoWithText />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
