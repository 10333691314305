import { useEffect } from 'react';
import { useAuth0 } from '../../react-auth0-wrapper';

const useAuthenticated = (path: string = `${window.location.pathname}${window.location.search}`) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);
};

export default useAuthenticated;
