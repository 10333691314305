import axios from 'axios';
import { useAuth0 } from '../../react-auth0-wrapper';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
});

const useAxios = () => {
  const { apiToken } = useAuth0();

  const get = async (path: string, otherHeaders?: any) => {
    if (!apiToken) {
      console.error('API token could not be found.');
      // TODO: handle case with no token
    }
    return axiosInstance.get(path, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        ...otherHeaders,
      },
    })
      .then((response) => response)
      .catch((error) => error.response);
  };

  const getInstallerInvoice = (bcId: number, fileName: string) => get(`/partners/billing-cycles/${bcId}/invoices/${fileName}`);

  const getInstallerInvoiceData = (bcId: number, fileName: string) => get(`/partners/billing-cycles/${bcId}/invoices/${fileName}?format=json`);

  const getInstallerBillingCycles = () => get('/partners/billing-cycles');

  const getInstallerInvoiceFileNames = () => get('/partners/invoice-file-names');

  return {
    getInstallerInvoice,
    getInstallerInvoiceData,
    getInstallerBillingCycles,
    getInstallerInvoiceFileNames,
  };
};

export default useAxios;
