import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Sheet from '../Sheet/Sheet';

const useStyles = makeStyles(() => ({
  errorNotification: {
    width: '100%',
    height: 'calc(100% - 90px)',
    transitionProperty: 'opacity',
    transitionDuration: '0.5s',
    opacity: 0,
    pointerEvents: 'none',
  },
  displayError: {
    opacity: 1,
  },
}));

type ErrorProps = {
  shouldDisplay: boolean;
};

const Error: React.FC<ErrorProps> = ({ shouldDisplay }) => {
  const classes = useStyles();

  return (
    <Sheet
      className={clsx(
        classes.errorNotification,
        shouldDisplay && classes.displayError,
      )}
    >
      <Typography>
        エラーが発生しました。時間をおいて再度お試しください。
      </Typography>
    </Sheet>
  );
};

export default Error;
