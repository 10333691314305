import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  headerText: {
    fontWeight: 400,
    lineHeight: 1.17,
    fontSize: 34,
    '@media (max-width: 767px)': {
      fontSize: 24,
    },
  },
}));

type PageTemplateProps = {
  pageTitle: string;
  children: any;
};

const PageTemplate: React.FC<PageTemplateProps> = ({ pageTitle, children }) => {
  const classes = useStyles();
  return (
    <>
      <Typography
        className={classes.headerText}
        component="h1"
        gutterBottom={true}
      >
        {pageTitle}
      </Typography>
      {children}
    </>
  );
};

export default PageTemplate;
