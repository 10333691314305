import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Sheet, LogoWithText, SuncleButton } from '../../components';
import { useAuth0 } from '../../react-auth0-wrapper';

const useStyles = makeStyles((theme) => ({
  loginButton: {
    margin: '14px 0',
    backgroundColor: '#5d2f91',
    color: '#fff',
    '&:hover': {
      color: '#5d2f91',
    },
  },
  loginPanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 420,
    maxWidth: '100%',
    margin: '80px auto 0',
  },
  welcomeText: {
    margin: '24px auto',
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();

  return (
    <Sheet className={classes.loginPanel}>
      <LogoWithText />
      <Typography className={classes.welcomeText}>
        Suncle パートナーズへようこそ
      </Typography>
      <SuncleButton onClick={() => loginWithRedirect({})}>
        ログイン
      </SuncleButton>
    </Sheet>
  );
};

export default Login;
