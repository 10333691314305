import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '../../react-auth0-wrapper';

const useStyles = makeStyles((theme) => ({
  loadingBounded: {
    width: '100%',
    height: 'calc(100% - 90px)',
    position: 'absolute',
    top: 90,
    left: 0,
    backgroundColor: '#ddd',
    zIndex: 1,
    transitionProperty: 'opacity',
    transitionDuration: '0.5s',
    opacity: 1,
    pointerEvents: 'none',
    // '@media (max-width: 1040px) and (min-width: 768px)': {
    //   height: 600
    // },
    // '@media (max-width: 767px)': {
    //   height: '75vw',
    //   top: '62px'
    // }
  },
  loadingIcon: {
    width: 105,
    height: 120,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: '-52px auto',
    '@media (max-width: 767px)': {
      transform: 'translate(-50%, -50%) scale(0.9, 0.9)',
      top: '60%',
      margin: '-49.4px auto',
    },
  },
  polItem: {
    width: 22,
    height: 90,
    position: 'absolute',
    top: 15,
    transform: 'translateY(-50%) skew(-15deg)',
  },
  pol01: {
    backgroundColor: '#f39800',
    animation: '$anim_p01 0.5s infinite linear alternate',
    left: 27,
  },
  pol02: {
    backgroundColor: '#5a3087',
    animation: '$anim_p02 0.5s infinite linear alternate',
    right: 27,
  },
  fadeOut: {
    opacity: 0,
  },
  '@keyframes anim_p01': {
    '0%': {
      transform: 'translate(-2px, 7px) skew(-15deg)',
    },
    '50%': {
      transform: 'translateY(0) skew(-15deg)',
    },
    '100%': {
      transform: 'translate(2px, -7px) skew(-15deg)',
    },
  },
  '@keyframes anim_p02': {
    '0%': {
      transform: 'translate(2px, -7px) skew(-15deg)',
    },
    '50%': {
      transform: 'translateY(0) skew(-15deg)',
    },
    '100%': {
      transform: 'translate(-2px, 7px) skew(-15deg)',
    },
  },
}));

const Loading: React.FC = () => {
  const [shouldFadeOut, setShouldFadeOut] = useState(true);
  const { loading } = useAuth0();
  const classes = useStyles();

  useEffect(() => {
    if (loading) {
      setShouldFadeOut(false);
    } else {
      setTimeout(() => {
        setShouldFadeOut(true);
      }, 500);
    }
  }, [loading]);

  return (
    <div
      className={clsx(classes.loadingBounded, shouldFadeOut && classes.fadeOut)}
    >
      <div className={classes.loadingIcon}>
        <div className={clsx(classes.polItem, classes.pol01)} />
        <div className={clsx(classes.polItem, classes.pol02)} />
      </div>
    </div>
  );
};

export default Loading;
