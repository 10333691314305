const formatDate = (date: string | Date | null | undefined) => {
  if (date) {
    const formattedDate = typeof date === 'string' ? new Date(date) : date;
    const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return formattedDate
      .toLocaleString('JA', dateOptions)
      .replace(new RegExp(/\//gi), '-');
  }
  return '';
};

export default formatDate;
