import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export type LeadProps = {
  leadId: number;
  staffEvalDate: string;
  nameKanji: string;
  nameKana: string;
  phone: string;
  email: string;
  address: string[];
};

type LeadTableProps = {
  isDefaultOpen?: boolean;
  leads: LeadProps[];
};

const useStyles = makeStyles(() => ({
  tableText: {
    whiteSpace: 'nowrap',
  },
  tableSubTitle: {
    paddingLeft: 7,
  },
}));

const formatAddress = (address: string[]) => {
  return address.map((addressLine, index) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={index}>
        {addressLine}
        <br />
      </Fragment>
    );
  });
};

const LeadTable: React.FC<LeadTableProps> = ({ leads }) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.tableSubTitle}>Leads</Typography>
      <Table size="small" className={classes.tableText}>
        <TableHead>
          <TableRow>
            <TableCell>Lead ID</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Address</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leads.map((lead) => (
            <TableRow key={lead.leadId}>
              <TableCell>{lead.leadId}</TableCell>
              <TableCell>{lead.staffEvalDate}</TableCell>
              <TableCell>
                {lead.nameKanji}
                <br />
                {lead.nameKana}
              </TableCell>
              <TableCell>{lead.phone}</TableCell>
              <TableCell>{lead.email}</TableCell>
              <TableCell>{formatAddress(lead.address)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default LeadTable;
