import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PageTemplate from '../PageTemplate';
import { FlexRow, FlexItem, Sheet } from '../../components';

const useStyles = makeStyles((theme) => ({
  dashboardIcon: {
    marginRight: '8px;',
  },
  featureTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  featureLink: {
    color: '#e59924',
  },
}));

const Dashboard: React.FC = () => {
  const classes = useStyles();

  return (
    <PageTemplate pageTitle="Dashboard">
      <Sheet>
        <Typography
          align="center"
          gutterBottom={true}
          variant="h5"
          component="p"
        >
          Welcome to the Suncle Partners dashboard!
        </Typography>
        <Typography>
          Thank you for partnering with us. This site is still under
          construction, and we plan to add more features that you will love in
          the coming weeks and months.
        </Typography>
      </Sheet>
      <Sheet>
        <FlexRow>
          <FlexItem>
            <p className={classes.featureTitle}>
              <AssignmentIcon className={classes.dashboardIcon} />
              <Typography variant="h6" component="span">
                Invoices
              </Typography>
            </p>
            <p>
              View and download your lead and refund invoice reports. Billing
              cycle runs on the 20th of each month.
            </p>
            <p>
              <Link to="/invoice" className={classes.featureLink}>
                Check your invoices here
              </Link>
            </p>
          </FlexItem>
          <FlexItem />
          <FlexItem />
        </FlexRow>
      </Sheet>
    </PageTemplate>
  );
};

export default Dashboard;
