import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import history from './history';

const DEFAULT_REDIRECT_CALLBACK = () => history.replace(`${window.location.pathname}${window.location.search}`, { title: document.title });

type Auth0ProviderProps = {
  children: any;
  onRedirectCallback: (appState: any) => void;
  domain: string;
  client_id: string;
  redirect_uri: string;
  audience: string;
};

type ContextArgs = {
  isAuthenticated: boolean;
  apiToken: string;
  user: any;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup: any;
  handleRedirectCallback: any;
  getIdTokenClaims: (p?: any) => void;
  loginWithRedirect: (p?: any) => void;
  getTokenSilently: (p?: any) => Promise<any>;
  getTokenWithPopup: (p?: any) => void;
  logout: (p?: any) => void;
};

export const Auth0Context = React.createContext({} as ContextArgs);
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider: React.FC<Auth0ProviderProps> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [token, setToken] = useState();

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);
      if (window.location.search.indexOf('code=') !== -1) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsUserAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        const t = await auth0FromHook.getTokenSilently();
        setToken(t);
        setCurrentUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    const t = await auth0Client.getTokenSilently();
    setToken(t);
    setCurrentUser(user);
    setIsUserAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    const t = await auth0Client.getTokenSilently();
    setToken(t);
    setLoading(false);
    setIsUserAuthenticated(true);
    setCurrentUser(user);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated: isUserAuthenticated,
        user: currentUser,
        apiToken: token,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
