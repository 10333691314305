import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import { useAuth0 } from '../../react-auth0-wrapper';

const useStyles = makeStyles((theme) => ({
  sideNavIcon: {
    color: '#e59924',
  },
  logOutIcon: {
    marginTop: 'auto',
  },
  icon: {
    minWidth: 46,
  },
}));

const SideNavItems: React.FC = () => {
  const { logout, user } = useAuth0();
  const classes = useStyles();

  return (
    <>
      {user && user.featureFlag && (
        <List>
          <Link to="/">
            <ListItem button>
              <ListItemIcon className={classes.icon}>
                <DashboardIcon className={classes.sideNavIcon} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>
          <Divider />
          <Link to="/invoice">
            <ListItem button>
              <ListItemIcon className={classes.icon}>
                <AssignmentIcon className={classes.sideNavIcon} />
              </ListItemIcon>
              <ListItemText primary="Invoice" />
            </ListItem>
          </Link>
          <Divider />
          <Link to="/reset-password">
            <ListItem button>
              <ListItemIcon className={classes.icon}>
                <VpnKeyIcon className={classes.sideNavIcon} />
              </ListItemIcon>
              <ListItemText primary="パスワード変更" />
            </ListItem>
          </Link>
          <Divider />
        </List>
      )}
      {(!user || (user && !user.featureFlag)) && (
        <List>
          <Link to="/">
            <ListItem button>
              <ListItemIcon className={classes.icon}>
                <AssignmentIcon className={classes.sideNavIcon} />
              </ListItemIcon>
              <ListItemText primary="案件明細ダウンロード" />
            </ListItem>
          </Link>
          <Divider />
          <Link to="/reset-password">
            <ListItem button>
              <ListItemIcon className={classes.icon}>
                <VpnKeyIcon className={classes.sideNavIcon} />
              </ListItemIcon>
              <ListItemText primary="パスワード変更" />
            </ListItem>
          </Link>
          <Divider />
        </List>
      )}
      <List className={classes.logOutIcon}>
        <ListItem button onClick={() => logout({ redirectTo: `${window.location.origin}/login` })}>
          <ListItemIcon className={classes.icon}>
            <PowerIcon className={classes.sideNavIcon} />
          </ListItemIcon>
          <ListItemText primary="ログアウト" />
        </ListItem>
      </List>
    </>
  );
};

export default SideNavItems;
