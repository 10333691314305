import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuth0 } from './react-auth0-wrapper';
import {
  Header,
  SideNav,
  AppWrapper,
  Loading,
} from './components';
import {
  SimpleInvoice,
  ResetPassword,
} from './pages';

const App: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(window.innerWidth > 767);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const { isAuthenticated } = useAuth0();

  const TopRedirect = () => <Redirect to="/" />;

  return (
    <>
      <Header
        handleDrawerOpen={handleDrawerOpen}
        drawerOpen={isAuthenticated && drawerOpen}
      />
      <SideNav
        handleDrawerClose={handleDrawerClose}
        drawerOpen={drawerOpen}
      />
      <Loading />
      <AppWrapper>
        <Switch>
          <Route
            path="/reset-password"
            exact
            component={ResetPassword}
          />
          <Route path="/login" exact component={TopRedirect} />
          <Route path="/" component={SimpleInvoice} />
        </Switch>
      </AppWrapper>
    </>
  );
};

export default App;
