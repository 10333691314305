import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  suncleButton: {
    whiteSpace: 'nowrap',
    marginTop: 14,
    backgroundColor: '#5d2f91',
    color: '#fff',
    display: 'inline-flex',
    '&:hover': {
      backgroundColor: '#4D2777',
    },
    '&:disabled': {
      color: '#5d2f91',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

type SuncleButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  startIcon?: React.ReactNode;
};

const SuncleButton: React.FC<SuncleButtonProps> = ({
  onClick, disabled, className, children, startIcon,
}) => {
  const classes = useStyles();
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={clsx(classes.suncleButton, className && className)}
      variant="outlined"
      startIcon={startIcon}
    >
      {children}
    </Button>
  );
};

export default SuncleButton;
