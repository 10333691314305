import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import axios from '../../axios';
import PageTemplate from '../PageTemplate';
import { useAuth0 } from '../../react-auth0-wrapper';
import { Sheet, SuncleButton, PendingBar, Error } from '../../components';

const ResetPassword: React.FC = () => {
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const [passwordRequestSending, setPasswordRequestSending] = useState(false);
  const [passwordSent, setPasswordSent] = useState(false);
  const { getTokenSilently } = useAuth0();

  const sendPsswordResetEmail = async () => {
    setPasswordRequestSending(true);
    if (!passwordSent) {
      getTokenSilently()
        .then((token) => {
          return axios.post(
            '/request-password-change',
            {
              token,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/zip',
              },
            },
          );
        })
        .then(() => {
          setPasswordSent(true);
          setPasswordRequestSending(false);
        })
        .catch(() => {
          setPasswordRequestSending(false);
          setShouldDisplayError(true);
        });
    }
  };

  return (
    <PageTemplate pageTitle="パスワード変更">
      {shouldDisplayError && <Error shouldDisplay={shouldDisplayError} />}
      {!shouldDisplayError && (
        <Sheet>
          <Typography>
            パスワード変更ボタンを押してください。<br />当アカウントに登録されたEメールアドレス宛てに、パスワード変更を行うリンクをお送りします。
          </Typography>
          <div>
            <SuncleButton
              onClick={sendPsswordResetEmail}
              disabled={passwordRequestSending || passwordSent}
            >
              パスワード変更
            </SuncleButton>
          </div>
          {passwordRequestSending && <PendingBar />}
          {passwordSent && <Typography>Eメールが送信されました！</Typography>}
        </Sheet>
      )}
    </PageTemplate>
  );
};

export default ResetPassword;
