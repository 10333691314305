import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from '../Copyright/Copyright';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: {
    height: 90,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    minHeight: 'calc(100vh - 90px)',
    flexDirection: 'column',
  },
}));

const AppWrapper: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        {children}
        <Copyright />
      </Container>
    </main>
  );
};

export default AppWrapper;
